import React from 'react'
import {graphql} from 'gatsby'
import { NewsArticle, SEO, Layout, Footer} from 'components'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  getNewsUrl,
  toPlainText
} from "../lib/helpers";

export const query = graphql`
  query NewsArticleTemplateQuery($id: String!) {
    post: sanityArticle(id: {eq: $id}) {
      id
      publishedAt
      newsCategories {
        _id
        title
      }
      mainImage {
        asset {
          fluid(maxWidth: 600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
    }
    recentPosts: allSanityArticle(
      limit: 2
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null }, id: { ne: $id } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const NewsArticleTemplate = props => {
  const {data, errors} = props
  const post = data && data.post
  const recentPosts = (data || {}).recentPosts
  ? mapEdgesToNodes(data.recentPosts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
  : [];

  return (
    <Layout>
      {post && <SEO title={post.title || 'Untitled'} description={toPlainText(post._rawExcerpt)} image={post.mainImage} />}
      {post && <NewsArticle post={post} recentPosts={recentPosts} />}
      <Footer/>
    </Layout>
  )
}

export default NewsArticleTemplate
